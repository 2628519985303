import axios from 'axios';

export const getBusinesses = async () => {
    const query = `
        query businesses { 
            businesses {
                _id
                name
                owners {
                    name
                }
                description
                url
                category
                img
            }
        }
    `;

    const payload = { 
        query
    };

    const headers = { "Content-type": 'application/json' };
    const response = await axios.post('https://compassion-project.herokuapp.com/graphql', payload, { headers });
    return response.data.data.businesses;
};

export const getBusiness = async(id) => {
    const query = `
        query getBusinessById($id: ID!) {
            getBusinessById(id: $id) {
                _id
                name
                owners {
                    name
                }
                description
                url
                category
            }
        }
    `;
    const variables = { id };
    const payload = {
        query,
        variables
    };
    const headers = { "Content-Type": 'application/json' };
    const response = await axios.post('https://compassion-project.herokuapp.com/graphql', payload, { headers });
    return response.data.data.getBusinessById;
}