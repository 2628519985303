/**
 * Author: Natalie Kappele-Miller
 * App: BLM Project
 * Date: March 10, 2021
 */
import React from 'react';

import TimelinePreview from '../Timeline/TimelinePreview';

import './Main.css';

import bannerImgLg from '../../assets/civil-rights-march.png';
import bannerImgSm from '../../assets/blm-protest-mother-son.png';
import bannerImgSmTwo from '../../assets/blm-protest.jpg';
import police from '../../assets/police-brutality-grp.png';
import justice from '../../assets/justice-grp.png';
import graph from '../../assets/encarcerations-graph.png';

import BusinessFeatured from '../Business/BusinessFeatured';
import DonatePreview from '../Donate/DonatePreview';

const Main = (props) => {
    console.log(props)
    const { history } = props;
    return (
        <div className="Main">
            <div className="Main__Header">
                <div className="Main__HeaderLg">
                    Matter is the
                </div>
                {/* <div className="Main__HeaderSm"><div>is</div><div>the</div></div> */}
                <div className="Main__HeaderLg Main__HeaderLg--red">
                    Minimum
                </div>
            </div>
            <div className="Main__BannerImages">
                <div className="Main__BannerImgWrapper">
                    <img alt="Civil rights march on Washington, D.C. Film negative by photographer Warren K. Leffler, 1963. From the U.S. News &amp; World Report Collection. Library of Congress Prints &amp; Photographs Division." src={bannerImgLg} className="Main__BannerImgLg" />
                    <div className="Main__BannerText">
                        Civil rights march on Washington, D.C. Film negative by photographer Warren K. Leffler, 1963. From the U.S. News &amp; World Report Collection. Library of Congress Prints &amp; Photographs Division.
                    </div>
                </div>
                <img alt="Black Lives Matter peaceful protest" src={bannerImgSmTwo} className="Main__BannerImgSm" />
                <img alt="Black Lives Matter peaceful protest" src={bannerImgSm} className="Main__BannerImgSm" />
            </div>
            <div className="Main__CalloutWrapper">
                <div className="Main__CalloutText">
                    To the end of <span className="Main__CalloutText Main__CalloutText--red">400+</span> years of systemic racism
                </div>
            </div>
            <TimelinePreview history={history} />
            <div className="Main__FocusWrapper">
                <div className="Main__FocusPoint">
                    <div className="Main__FocusContent">
                        <div className="Main__FocusPointHeader">Stop Police Brutality</div>
                        {/* <h3>End brutality, increase accountability</h3> */}
                        <p>
                            Not all cops are bad cops, but police are supposed to protect and serve. Having a disporportionate response of violence to a non-threatening crime such as walking home or trying to use counterfit currency, serves no one.
                            Over the years we have seen an overwhelming majoirty of unnecessary force used, especially with black people.

                        </p>
                    </div>
                    <img src={police} alt="Police brutality" className="Main__FocusImg" />
                </div>
                <div className="Main__FocusPoint">
                <img src={justice} alt="Victims" className="Main__FocusImg" />
                    <div className="Main__FocusContent">
                        <div className="Main__FocusPointHeader">Demand Justice</div>
                        {/* <h3>Demand legal justice</h3> */}
                        <p>On March 13th, in the middle of the night, Breonna Taylor was killed by Louisville police as they illegally executed a drug raid at the wrong address for a person they had already arrested earlier that day. No charges have been brought and no arrests have been made. Sadly, she is far from alone in her wrongful murder. Trayvon Martin, George Floyd, Tamir Rice, Elijah McClain are a few names you might know. The real tragedy is that there are thousands upon thousands of names that we don't know.</p>
                    </div>
                </div>
                <div className="Main__FocusPoint">
                    <div className="Main__FocusContent">
                        <div className="Main__FocusPointHeader">End prejudiced jailing</div>
                        {/* <h3>End discriminatory jailing</h3> */}
                        <p>We stand against cash bail as unjust and identify wealth-based discrimination as a vehicle for the criminal justice system to target populations for structural violence.
    (The Minnesota Freedom Fund)</p>
                    </div>
                    <div className="Main__FocusGraphWrapper">
                        <p className="Main__FocusGraphLabel">Number of people incarcerated in local jails by conviction status, 1978-2013</p>
                        <img src={graph} alt="Graph of incarcerations in local jails by conviction status" className="Main__FocusImg" />
                        <div className="Main__FocusGraphCitationWrapper">
                            <p className="Main__FocusGraphCitation">
                                Jail populations were adjusted to remove people being
                                held for federal and state authorities. 
                            </p>
                            <p className="Main__FocusGraphCitation">
                                Our Bureau of Justice Statistics data sources are described at www.prisonpolicy.org/reports/jailsovertime.html#methodology 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Main__BusinessesWrapper">
                <div className="Main__BusinessesHeader">Support Black Businesses</div>
                <BusinessFeatured />
            </div>
        <div className="Main__DonateWrapper">
            <div className="Main__DonateHeaderMain">Donate</div>
            <p className="Main__DonateCaption">
                We believe these organizations best align with the goals of the Black Lives Matter Movement.
            </p>
            <DonatePreview />
        </div>
        </div>
    )
}

export default Main;
