import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { selectAllBusinesses, fetchBusinesses } from '../components/Business/businessSlice';

/**
 * @function usePagination
 * Takes items to be paginated
 * @param {Array} items - items to be paginated
 * @param {Integer} totalItems - total number of items
 * @param {Integer} itemsPerPage - number of items per page 
 * @param {Array} categories - 
 * @returns {Object} { hasNext: Boolean, hasPrevious, Boolean, handleNext: Function, handlePrevious: Function }
 */
const usePagination = (itemsPerPage, categories) => {
    const defaultCategory = categories[0];

    // Initialize redux
    const dispatch = useDispatch();
    const businesses = useSelector(selectAllBusinesses);
    const [category, setCategory] = useState(defaultCategory);
    

    // Change businesses displayed by category
    // Reset range, index, page, hasNext, hasPrevious to inital values
    const filterBusinesses = (categoryProp) => {
        let newFilter = businesses.filter((business) => {
            return business.category === categoryProp
        });
        setCategory(categoryProp);
        setFilteredBusinesses(newFilter);
        setRange(newFilter.slice(0,6));
        setIndex(0)
        setPage(1);
        setHasNext(true);
        setHasPrevious(false);
    }

    const businessesStatus = useSelector(state => state.businesses.status);

    const [page, setPage] = useState(1);
    const [index, setIndex] = useState(0);
    const [range, setRange] = useState([]);
    const [hasNext, setHasNext] = useState(true);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [filteredBusinesses, setFilteredBusinesses] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (businessesStatus === 'idle') { 
            // initial api call
            dispatch(fetchBusinesses());
        }
    }, [businessesStatus, dispatch]);

    useEffect(() => {
        if (businesses && businesses !==[] && category && itemsPerPage) {
            let filter = businesses.filter((business) => {
                return business.category === category
            });
            setTotalPages(Math.ceil(filter.length/itemsPerPage));
            setFilteredBusinesses(filter);
            setRange(filter.slice(0,6));
            const itemToIndex = (filter.slice(0,6)[0]);
            const indexOfItem = filter.indexOf(itemToIndex)
            setIndex(indexOfItem);
        }
    }, [businesses,category,itemsPerPage]);

    /**
     * @function handleNext
     * Go to next page of results
     * Set first and last offset, pass values to slice, set hasNext, index and page values
     */
    const handleNext = () => {
        setHasPrevious(true);
        const newFirstOffset = index + itemsPerPage;
        const newLastOffset = (page + 1) * itemsPerPage;
        const newIndex = index + 6;
        const newPage = page + 1;
        // If last offset is greater than total items, set to length of items
        if (newLastOffset > filteredBusinesses.length) {
            setRange(filteredBusinesses.slice(newFirstOffset, filteredBusinesses.length));
            setHasNext(false);
            setIndex(newIndex);
            setPage(newPage);
        // Otherwise set offets to increments of 6
        } else {
            setRange(filteredBusinesses.slice(newFirstOffset, newLastOffset));
            setIndex(newIndex);
            setPage(newPage);
        }
    };

    /**
     * @function handlePrevious
     * Go to previous page of results
     * Set first and last offset, if first offset is 0 set hasPrevious to false
     */
    const handlePrevious = () => {
        setHasNext(true);
        const newIndex = index - 6;
        setIndex(newIndex);
        const newFirstOffset = (index) - itemsPerPage;
        const newLastOffset = (page - 1) * itemsPerPage;
        const newRange = filteredBusinesses.slice(newFirstOffset, newLastOffset);
        setRange(newRange);
        if (newFirstOffset === 0) {
            setHasPrevious(false);
        }
        const newPage = page - 1;
        setPage(newPage)
    };

    return {
        hasNext,
        hasPrevious,
        handleNext,
        handlePrevious,
        range,
        page,
        totalPages,
        filterBusinesses,
        filteredBusinesses,
        category
    }
};

export default usePagination;