import React, { useState } from 'react';
import { NavLink, BrowserRouter as Router, } from 'react-router-dom';

import Logo from '../../assets/blm-logo-01.svg';
import './Header.css';


const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const renderMobileMenu = () => {
        if(isMenuOpen) {
            return (
                <div className="Header__MobileMenu">
                    <div className="Header__Mobile">
                        <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/" exact>
                            Mission
                        </NavLink>
                        <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/businesses" exact>
                            Businesses
                        </NavLink>
                        {/* <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/community" exact>
                            Community
                        </NavLink> */}
                        <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/donate" exact>
                            How to Help
                        </NavLink>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="Header__MobileMenu--closed">

                </div>
            )
        }
    }

    return (
        <Router forceRefresh={true}>
            <div className="Header__Bg">
                <div className="Header__Wrapper">
                    <div className="Header__MainLinks">
                        <NavLink to="/" exact><img src={Logo} className="Logo" alt="Compassion Project" /></NavLink>
                        <div className="Header__Desktop">
                            <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/" exact>
                                Mission
                            </NavLink>
                            <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/businesses" exact>
                                Businesses
                            </NavLink>
                            {/* <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/community" exact>
                                Community
                            </NavLink> */}
                            <NavLink className="Header__Link" activeClassName="Header__Link--active" to="/donate" exact>
                                How to Help
                            </NavLink>
                        </div>
                    </div>
                    {/* <div className="Header__AuthLinks">
                        <div className="Header__LinkLogin">Login</div>
                        <SignupButton />
                    </div> */}
                    <div className={isMenuOpen ? "Header__MobileHamburger--open" : "Header__MobileHamburger--closed"} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {Array(3).fill('').map((item, index) => {
                            return (
                                <div key={index} className="Header__MobileHamLine">
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {renderMobileMenu()}
            </div>
        </Router>
    )
}

export default Header;