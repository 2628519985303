import React from 'react';
import { NavLink } from 'react-router-dom';

import './Footer.css';

import Logo from '../../assets/blm-logo-01.svg';

const Footer = () => {
    return (
        <div className="Footer__Background">
            <div className="Footer__Wrapper">
                <div className="Footer__Row">
                    <div className="Footer__Group">
                        <div className="Footer__Col">
                            <NavLink to="/" exact><img src={Logo} className="Logo" alt="Compassion Project" /></NavLink>
                            <ul className="Footer__Links">
                                <li><NavLink to="/" exact>Mission</NavLink></li>
                                <li><NavLink to="/businesses" exact>Businesses</NavLink></li>
                                {/* <li><NavLink to="/community" exact>Community</NavLink></li> */}
                                <li><NavLink to="/donate" exact>How to Help</NavLink></li>
                            </ul>
                        </div>
                        <div className="Footer__Col">
                            <ul className="Footer__Links">
                                <li>Timeline</li>
                                <li>Resources</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="Footer__Social">
                        <img src={Fb} alt="Facebook" className="Footer__SocialIcon" />
                        <img src={Ig} alt="Instagram" className="Footer__SocialIcon" />
                        <img src={Twt} alt="Twitter" className="Footer__SocialIcon" />
                        <img src={Yt} alt="YouTube" className="Footer__SocialIcon" />
                    </div> */}
                </div>
                <div className="Footer__Copyright">
                    &copy; Compassion Project 2020 All rights reserved
                </div>
            </div>
        </div>
    )
}

export default Footer;