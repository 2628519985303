/**
 * Author: Natalie Kappele-Miller
 * App: BLM Project
 * Date: March 10, 2021
 */
import React from 'react';
import Main from '../components/Main/Main';

class MainContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        return (
            <div><Main {...this.props} /></div>
        )
    }
}

export default MainContainer;