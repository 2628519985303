import React from 'react';
import './BusinessSpotlight.css';

import hero from '../../assets/bloom-plume-01.png';

const BusinessSpotlight = () => {
    const data = {
        busImg: hero,
        name: 'Blume & Plume Coffee',
        owner: 'Maurice Harris',
        reason: 'Intimate design & detail. Building beloved community.'
    }

    const { busImg, name, owner, reason } = data;
    return (
        <div className="BusinessSpotlight__Wrapper">
            <div className="BusinessSpotlight__Layout">
                <img src={busImg} alt="Hero" />
                <div className="content">
                    <h1>{name}</h1>
                    <p>{owner}</p>
                    <h1>Why we love it</h1>
                    <p>{reason}</p>
                </div>
            </div>
        </div>
    )
}

export default BusinessSpotlight;