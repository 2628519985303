import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useTimeline from '../../hooks/useTimeline';

import { selectDateById } from './timelineSlice';

import './TimelineDetail.css';

import { getYear } from '../../utils/dates';

import breadcrumbArrow from '../../assets/breadcrumb-arrow.png';
import Arrow from '../../assets/timeline-control.svg';

/**
 * @function TimelineDetail
 * Accepts match, returns TimelineDetail component for the id of the route
 * 
 * @param {Object} match match property on history object to get the id param
 * @returns {Component}
 */
const TimelineDetail = ({ match }) => {
    const [dateId, setDateId] = useState(match.params.id);
    // Grab page info with useTimeline hook
    const pageInfo = useTimeline(9);

    // Redux initializations
    const date = useSelector((state) => selectDateById(state, dateId));

    const { 
        dateRange, 
        handleNext, 
        handlePrevious, 
        hasNext, 
        hasPrevious, 
        setNewActiveDate, 
        activeDate 
    } = pageInfo;

    const [, ...rest] = dateRange;

    // If activeDate value changes, set dateId to new _id
    // to make new redux call
    if (activeDate && dateId !== activeDate._id) {
        setDateId(activeDate._id);
    }

    return (
        <div className="TimelineDetail__Bg">
            <div className="TimelineDetail__Overlay">
                <div className="TimelineDetail__Content">
                    <div className="TimelineDetail__Breadcrumbs">
                        <div className="TimelineDetail__Crumb">
                            <NavLink className="TimelineDetail__Link" to="/" exact>Mission</NavLink>
                            <img alt="carot" src={breadcrumbArrow} />
                        </div>
                        <div className="TimelineDetail__Crumb TimelineDetail__Crumb--current">Timeline</div>
                    </div>
                    <div className="TimelineDetail__Header">Historic Timeline</div>
                    <div className="TimelineDetail__TimelineLayout">
                        <div className="TimelineDetail__Controls">
                            <img src={Arrow} onClick={handlePrevious} alt="Next" className={hasPrevious ? "TimelinePreview__Previous" : "TimelinePreview__Previous TimelinePreview__Previous--disabled"} />
                            <img src={Arrow} onClick={handleNext} alt="Previous" className={hasNext ? "TimelinePreview__Next" : "TimelinePreview__Next TimelinePreview__Next--disabled"} />
                        </div>
                        <div className="TimelineDetail__TimelineDates">
                            <div className="TimelineDetail__Active">
                                <div className="TimelineDetail__Marker--active"></div>
                                <div className="TimelineDetail__ActiveDate">{getYear(date && date.date)}</div>
                            </div>
                            {rest.map((dateItem) => {
                                return (
                                    <div className="TimelineDetail__DateGroup" key={dateItem._id}>
                                        <NavLink to={`/timeline/${dateItem._id}`}>
                                            <div className="TimelineDetail__NextDate" onClick={() => setNewActiveDate(dateItem)}>
                                                <div className="TimelineDetail__Marker"></div>
                                                <div className="TimelineDetail__NextDateDate">{getYear(dateItem.date)}</div>
                                            </div>
                                        </NavLink>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="TimelineDetail__ActiveContent">
                            <h1>{date && date.title}</h1>
                            {date && Array.isArray(date.description) 
                                ? date.description.map((paragraph, index) => {
                                    return <p key={index}>{paragraph}</p>
                                })
                                : <p>{date && date.description}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimelineDetail;