import React from 'react';

import useTimeline from '../../hooks/useTimeline';

import { getYear } from '../../utils/dates';

import TulsaImg from '../../assets/tulsa-img.png';
import Arrow from '../../assets/timeline-control.svg';

import './TimelinePreview.css'

/**
 * @function TimelinePreview
 * Returns preview version of the historic timeline, which
 * has an active date and the next three dates.
 * 
 * @param {Object} history - history, used to navigate to detail
 * @returns @Component
 */
const TimelinePreview = ({ history }) => {

    const pageInfo = useTimeline(4);

    // Navigate to date detail
    const goToDetail = (id) => {
        history.push(`/timeline/${id}`);
    }

    const { 
        dateRange, 
        handleNext, 
        handlePrevious, 
        hasNext, 
        hasPrevious, 
        setNewActiveDate, 
        activeDate 
    } = pageInfo;

    const [, ...rest] = dateRange;

    const shorten = (str, maxLen, separator = ' ') => {
        let returnedString;
        if (Array.isArray(str)) {
            returnedString = str.toString();
        } else {
            returnedString = str;
        }
        if (returnedString.length <= maxLen) return returnedString;
        return returnedString.substr(0, returnedString.lastIndexOf(separator, maxLen));
    }

    if (!activeDate) {
        return (
            <div>Loading...</div>
        )
    }
    return (
        <div className="Timeline__Wrapper">
            <div className="TimelinePreview__H1">Educate yourself today, for a better tomorrow</div>

        <div className="TimelinePreview__Timeline">
                     <div className="TimelinePreview__Controls">
                        <img src={Arrow} onClick={handlePrevious} alt="Previous" className={hasPrevious ? "TimelinePreview__Previous" : "TimelinePreview__Previous TimelinePreview__Previous--disabled"} />
                        <img src={Arrow} onClick={handleNext} alt="Next" className={hasNext ? "TimelinePreview__Next" : "TimelinePreview__Next TimelinePreview__Next--disabled"} />
                    </div>
                        <div className="TimelinePreview__TimelineContent">
                            <div className="TimelinePreview__ActiveContent">
                                <div onClick={() => goToDetail(activeDate && activeDate._id)} className="TimelinePreview__ActiveDate">
                                    <div className="TimelinePreview__Marker TimelinePreview__Marker--active"></div>
                                    <div className="TimelinePreview__FocusDate">{getYear(activeDate && activeDate.date)}</div>
                                </div>
                                 <div className="TimelinePreview__TimelineTextWrapper">
                                     <div className="TimelinePreview__Marker TimelinePreview__Marker--invisible"></div>
                                     <div className="TimelinePreview__TimelineActiveText">
                                         <h1 onClick={() => goToDetail(activeDate && activeDate._id)}>{activeDate && activeDate.title}</h1>
                                        {activeDate && Array.isArray(activeDate.description) 
                                            ? <p>{activeDate && shorten(activeDate.description, 300)}</p>
                                            : <p>{activeDate && shorten(activeDate.description, 300)}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="TimelinePreview__NextDates">
                                {rest.map((dateItem) => {
                                    return (
                                        <div key={dateItem._id} className="TimelinePreview__NextDate" onClick={() => setNewActiveDate(dateItem)}>
                                            <div className="TimelinePreview__Marker"></div>
                                            <div className="TimelinePreview__NextDateDate">{getYear(dateItem.date)}</div>
                                        </div> 
                                    )
                                })}
                            </div>
                        </div>
                    <div className="TimelinePreview__TimelineImage">
                        <img src={TulsaImg} alt="Timeline" />
                    </div>
                </div>
        </div>
    )
}

export default TimelinePreview;