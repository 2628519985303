export const getYear = (fullDate) => {
    if (fullDate) {
        const date = new Date(fullDate);
        return date.getFullYear();
    }
    return 'No date provided'
}

export const getIndexFromId = (activeDateParam, datesArray) => {
    const id = datesArray.find((date) => {
        return date._id === activeDateParam._id;
    });
    return datesArray.indexOf(id);
}