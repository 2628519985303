/**
 * Author: Natalie Kappele-Miller
 * App: BLM Project
 * Date: June 30, 2021
 */
import React from 'react';
import './BusinessCard.css';

import ownerIcon from '../../assets/owner-icon.svg';

import { ButtonSlim, SecondarySlim } from '../../shared/Buttons/Buttons';

const BusinessCard = ({ business, history }) => {
    const { _id, name, owners, description, url, img } = business;

    // Navigate to date detail
    const goToDetail = (id) => {
        history.push(`/business/${id}`);
    }

    return (
        <div className="BusinessCard__Wrapper" onClick={() => goToDetail(_id)}>
            <div className="BusinessCard__Banner">
                {/* Need to find image hosting platform */}
                <img src={img} alt="Business" />
            </div>
            <div className="BusinessCard__Content">
                <h3>{name}</h3>
                <div className="BusinessCard__OwnerRow">
                    <p className="BusinessCard__OwnerText">
                        {owners.map((owner, index) => { 
                            return (
                                <span className="BusinessCard__OwnerRow" key={index}>
                                    <img src={ownerIcon} alt="Owner" />
                                    <span className="BusinessCard__OwnerName">{owner.name}</span>
                                </span>
                            )
                        })}
                    </p>
                </div>
                <p className="BusinessCard__Desc">{description}</p>
                <div className="BusinessCard__BtnRow">
                    <ButtonSlim ctaText={'Visit Website'} ctaLink={url} />
                    <SecondarySlim ctaText={'Learn More'} onClick={() => goToDetail(_id)} />
                </div>
            </div>
        </div>
    )
}

export default BusinessCard;