/**
 * Author: Natalie Kappele-Miller
 * App: BLM Project
 * Date: January 4, 2021
 */
import React from 'react';
import {
	Route,
  Switch,
  withRouter
} from 'react-router-dom';

import "./App.css";

// Containers & Components
import MainContainer from './containers/main';
import BusinessesPage from './components/Business/BusinessesPage';
import CommunityContainer from './containers/community';
import DonateContainer from './containers/donate';
import TimelineDetail from './components/Timeline/TimelineDetail';
import Layout from './containers/layout';
import BusinessDetail from './components/Business/BusinessDetail';

// Images
import equalJusticeTxt from './assets/equal-justice-text.png';
import equalJusticeImg from './assets/eji-image.png';
import mffText from './assets/minff-text.png';
import mffImg from './assets/george-floyd-protest.png';
import bailProjText from './assets/bail-proj-text.png';
import bailProjImg from './assets/bail-proj-img.png';
import bamImg from './assets/HBO-blackandmissing.jpg';
import bamText from './assets/blackmissingtext.png';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      charities: [
        {
          name: 'Equal Justice Initiative',
          description: 'The Equal Justice Initiative is committed to ending mass incarceration and excessive punishment in the United States, to challenging racial and economic injustice, and to protecting basic human rights for the most vulnerable people in American society.',
          imgTxt: equalJusticeTxt,
          img: equalJusticeImg,
          donateLink: 'https://support.eji.org/give/153413/#!/donation/checkout'
      },
      {
          name: 'Minnesota Freedom Fund',
          description: 'The Minnesota Freedom Fund pays criminal bail and immigration bonds for those who cannot otherwise afford to as we seek to end discriminatory, coercive, and oppressive jailing.',
          imgTxt: mffText,
          img: mffImg,
          donateLink: 'https://www.givemn.org/organization/Minnesota-Freedom-Fund'
      },
      {
          name: 'The Bail Project',
          description: 'The Bail Project combats mass incarceration by disrupting the money bail system—one person at a time. We restore the presumption of innocence, reunite families, and challenge a system that criminalizes race and poverty. We’re on a mission to end cash bail and create a more just, equitable, and humane pretrial system.',
          imgTxt: bailProjText,
          img: bailProjImg,
          donateLink: 'https://bailproject.org/?form=donate'
      },
      {
          name: 'Black & Missing Foundation',
          description: 'former law enforcement officer Derrica and public relations expert Natalie operate a grassroots organization that raises awareness in communities, alerts media outlets to the plight of thousands of women and children and urges police departments to allocate more resources to this scourge. Cases of missing Black people remain unresolved four times longer than those of white people. Black And Missing pulls back the curtain to explore how systemic behaviors and attitudes stem from centuries of deeply rooted racism.',
          imgTxt: bamText,
          img: bamImg,
          donateLink: 'https://www.blackandmissinginc.com/donate/'
      }
      ]
    };
  }

  render() {
    return (
      <div className="App">
        <Layout history={this.props.history} children={this.props.children} {...this.props}>
            <Switch>
              <Route {...this.props} path="/" exact component={MainContainer}  />
              <Route {...this.props} path="/businesses" exact component={BusinessesPage} />
              <Route {...this.props} path="/business/:id" exact component={BusinessDetail} />
              <Route path="/community" exact component={CommunityContainer} />
              <Route path="/donate" exact component={DonateContainer} />
              <Route {...this.props} path="/timeline/:id" exact component={TimelineDetail} />
            </Switch>
        </Layout>
      </div>
    );
  }
}

export default withRouter(App);
