import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectAllDates, fetchDates } from '../components/Timeline/timelineSlice';

import { getIndexFromId } from '../utils/dates';

/**
 * @function Timeline
 * Agnostic timeline component that will handle all Timeline logic
 * @param {Number} length - length of the timeline array that is visible
 * @returns 
 */
const useTimeline = (length) => {
    const defaultDate = {
        _id: '',
        date: '',
        description: [],
        img: '',
        title: ''
    };
    // Redux
    const dispatch = useDispatch();
    const dates = useSelector(selectAllDates);
    const datesStatus = useSelector(state => state.dates.status);
    // const error = useSelector(state => state.dates.error);

    const [activeDate, setActiveDate] = useState(defaultDate);
    const [dateRange, setDateRange] = useState([]);
    const [hasNext, setHasNext] = useState(true);
    const [hasPrevious, setHasPrevious] = useState(false);

    const firstElement = dates[0];
    const firstElementIndex = dates.indexOf(firstElement);
    const getLastElement = dates[dates.length - 1];
    const lastElementIndex = dates.indexOf(getLastElement);

    // Set active date, set new date range depending on which date was selected
    const setNewActiveDate = (newActiveDate) => {
        const index = getIndexFromId(newActiveDate, dates);
        const firstPosition = index;
        const lastPosition = index + length;
        setActiveDate(dates[index]);
        setDateRange(dates.slice(firstPosition, lastPosition));
    }

    // Go to next date
    const handleNext = () => {
        setHasPrevious(true);
        const index = getIndexFromId(activeDate, dates);
        const newFirstPage = index + 1;
        const newLastOffset = length + 1;
        const newLastPage = index + newLastOffset;
        // If current index doesn't equal last element of array,
        // create new slice from date range
        if (index !== lastElementIndex) {
            const newDateRange = dates.slice(newFirstPage, newLastPage);
            const newActiveDate = newDateRange[0];
            setDateRange(newDateRange);
            setActiveDate(newActiveDate);
            
        // If index does equal last element of array,
        // set hasNext to false
        } else if (index === lastElementIndex) {
            setHasNext(false);
        } else {
            const newDateRange = dates.slice(lastElementIndex - length, lastElementIndex);
            setDateRange(newDateRange);
        }
    }

    // Go to previous date
    const handlePrevious = () => {
        setHasNext(true);
        const index = getIndexFromId(activeDate, dates);
        const newFirstPage = index - 1;
        const newLastPage = length - 1;
        // const newLastPage = index + length - 1;
        if (index !== firstElementIndex) {
            setHasPrevious(true);
            const newDateRange = dates.slice(newFirstPage, newLastPage);
            const newActiveDate = newDateRange[0];
            setDateRange(newDateRange);
            setActiveDate(newActiveDate)
        } else {
            const newDateRange = dates.slice(0, length);
            setDateRange(newDateRange);
            setHasPrevious(false);
            setHasNext(true);
        }
    }

    // Call redux dispatch to make async request
    useEffect(() => {
        if (datesStatus === 'idle') {
            dispatch(fetchDates())
        }
    }, [datesStatus, dispatch]);

    // If dates is updated with data, set dateRange and activeDate
    useEffect(() => {
        if (dates && dates !== [] && length) {
            const newDateRange = dates.slice(0, length);
            setDateRange(newDateRange);
            setActiveDate(newDateRange[0]);
        }
    }, [dates, length]);

    return {
        handleNext,
        handlePrevious,
        hasNext,
        hasPrevious,
        setNewActiveDate,
        activeDate,
        dateRange
    }
}

export default useTimeline;