import React from 'react';

// Shared Components
import Header from '../shared/Header/Header';
import Footer from '../shared/Footer/Footer';

const Layout = ({ history, children }) => {
    if (history.location.pathname.includes('timeline')) {
        return (
            <>
            {children}
            </>
        )
    }
    return (
        <div className="Layout__Normal">
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default Layout;