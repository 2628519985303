import React from 'react';

import { Button } from '../../shared/Buttons/Buttons';

import './DonateSection.css';

const DonateSection = ({ charity }) => {
    console.log('charity:', charity)
    const { name, img, description } = charity;
    return (
        <>
        {/* <div className="DonateSection__DonateText">
            <img src={imgTxt} alt="Equal Justice Initiative" className="DonateSection__DonateTextText" />
        </div> */}
        <div className="DonateSection__Wrapper">
            <div className="DonateSection__DonateContent">
                <div className="DonateSection__DonateHeader">{name}</div>
                {description.map((p) => {
                    return <p>{p}</p>
                })}
                {/* <p>{description}</p> */}
                <div className="DonateSection__ButtonWrapper">
                    <Button ctaText={`Donate Now`} />
                </div>
            </div>
            <div className="DonateSection__DonateImgSection">
                <img src={img} alt="Prisoners" className="DonateSection__DonateImgImg" />
            </div>
        </div>
        </>
    )
}

export default DonateSection;