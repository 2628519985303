import React from 'react';

import './Donate.css';

import DonateSection from './DonateSection';

import equalJusticeTxt from '../../assets/equal-justice-text.png';
import equalJusticeImg from '../../assets/eji-image.png';
import mffText from '../../assets/minff-text.png';
import mffImg from '../../assets/george-floyd-protest.png';
import bailProjText from '../../assets/bail-proj-text.png';
import bailProjImg from '../../assets/bail-proj-img.png';
import bamImg from '../../assets/HBO-blackandmissing.jpg';
import bamText from '../../assets/blackmissingtext.png';

const data = [
    {
        name: 'Equal Justice Initiative',
        description: [
            'The Equal Justice Initiative is committed to ending mass incarceration and excessive punishment in the United States, to challenging racial and economic injustice, and to protecting basic human rights for the most vulnerable people in American society.',
            'Founded in 1989 by Bryan Stevenson, a widely acclaimed public interest lawyer and bestselling author of Just Mercy, EJI is a private, 501(c)(3) nonprofit organization that provides legal representation to people who have been illegally convicted, unfairly sentenced, or abused in state jails and prisons. We challenge the death penalty and excessive punishment and we provide re-entry assistance to formerly incarcerated people.'
        ],
        imgTxt: equalJusticeTxt,
        img: equalJusticeImg,
        donateLink: 'https://support.eji.org/give/153413/#!/donation/checkout'
    },
    {
        name: 'Minnesota Freedom Fund',
        description: [
            'The Minnesota Freedom Fund pays criminal bail and immigration bonds for those who cannot otherwise afford to as we seek to end discriminatory, coercive, and oppressive jailing.',
            'We envision a world where justice restores the humanity and dignity of all involved. We fight to exist in a world where every individual has access to resources to lead healthy and full lives.'
        ],
        imgTxt: mffText,
        img: mffImg,
        donateLink: 'https://www.givemn.org/organization/Minnesota-Freedom-Fund'
    },
    {
        name: 'The Bail Project',
        description: [
            'The Bail Project combats mass incarceration by disrupting the money bail system—one person at a time. We restore the presumption of innocence, reunite families, and challenge a system that criminalizes race and poverty. We’re on a mission to end cash bail and create a more just, equitable, and humane pretrial system.',
            'The seed for The Bail Project was planted over 10 years ago when The Bronx Freedom Fund, the first-of-its-kind nonprofit, revolving bail fund in the country, launched in New York City. Since then, The Bronx Freedom Fund has grown out of a determination to combat mass incarceration and racial disparities at the front end of the system. Because bail is returned at the end of a case, we are able to build a sustainable revolving fund where philanthropic dollars can be used several times per year, maximizing the impact of every contribution.'
        ],
        imgTxt: bailProjText,
        img: bailProjImg,
        donateLink: 'https://bailproject.org/?form=donate'
    },
    {
        name: 'Black & Missing Foundation',
        description: [
            'Former law enforcement officer Derrica and public relations expert Natalie operate a grassroots organization that raises awareness in communities, alerts media outlets to the plight of thousands of women and children and urges police departments to allocate more resources to this scourge. Cases of missing Black people remain unresolved four times longer than those of white people. Black And Missing pulls back the curtain to explore how systemic behaviors and attitudes stem from centuries of deeply rooted racism.',
        ],
        imgTxt: bamText,
        img: bamImg,
        donateLink: 'https://www.blackandmissinginc.com/donate/'
    }
];

const Donate = () => {
    return (
        <div className="Donate__Bg">
            <div className="Donate__Wrapper">
                <div className="Donate__Hero">
                    <div>
                        <div className="header">How to help</div>
                        <p>We've researched and confirmed the transparency of several charities that we believe best align with our cause. We suggest that you read about each of them and if you are excited and inspired by their work like we are, we can redirect you to their site to make a secure donation today. Donations make it possible for these charities to continue doing incredible work for our communities.</p>
                    </div>
                    <img src={bailProjImg} alt="The Bail Project" className="hero-image" />
                </div>
                {data.map((charity, index) => {
                    return <DonateSection charity={charity} key={index} />
                })}
            </div>
        </div>
    )
}

export default Donate;