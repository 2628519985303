import React from 'react';

import './Community.css';

const Community = () => {
    return (
        <div className="Community__Wrapper">Community</div>
    )
}

export default Community;