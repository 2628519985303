import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getBusinesses } from '../../actions/business';

const initialState = {
    businesses: [],
    status: 'idle',
    error: null
};

export const fetchBusinesses = createAsyncThunk('businesses/fetchBusinesses', async () => {
    const response = await getBusinesses();
    return response;
});

export const businessSlice = createSlice({
    name: 'businesses',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
        .addCase(fetchBusinesses.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchBusinesses.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.businesses = action.payload;
        })
        .addCase(fetchBusinesses.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message
        })
    }
});

export default businessSlice.reducer;

export const selectAllBusinesses = state => state.businesses.businesses;

export const selectBusinessById = (state, businessId) => state.businesses.businesses.find(business => business._id === businessId);