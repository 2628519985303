import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware } from 'redux';
// import promise from 'redux-promise-middleware';
// import { logger } from 'redux-logger';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store'

// import reducer from './store/reducers'

// let middleware = applyMiddleware(promise, logger);

// const store = createStore(reducer, middleware);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
