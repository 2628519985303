import './Buttons.css';

export const SignupButton = () => {
    return (
        <div className="SignupButton">Signup</div>
    )
}

export const Button = ({ ctaText, ctaLink }) => {
    return (
        <div className="Button">
            <a href={ctaLink}>{ctaText}</a>
        </div>
    )
}

export const SecondaryButton = ({ ctaText, ctaLink }) => {
    return (
        <div className="SecondaryButton">
            <a href={ctaLink} target="_blank" rel="noreferrer">{ctaText}</a>
        </div>
    )
}
export const ButtonSlim = ({ ctaText, ctaLink }) => {
    return (
        <div className="ButtonSlim">
            <a href={ctaLink} target="_blank" rel="noreferrer">{ctaText}</a>
        </div>
    )
}

export const SecondarySlim = ({ ctaText }) => {
    return (
        <div className="SecondarySlim">
            {ctaText}
        </div>
    )
}