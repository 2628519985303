import axios from 'axios';

export const getTimelineDates = async () => {
    const query = `
        query getTimelinePreview {
            getTimelinePreview {
                _id
                date
                title
                description
                img
            }
        }
    `;

    const payload = {
        query
    };

    const headers = { "Content-Type": 'application/json' };
    const response = await axios.post('https://compassion-project.herokuapp.com/graphql', payload, { headers });
    return response.data.data.getTimelinePreview;
};

export const getDate = async (id) => {
    const query = `
        query getTimelineDate($id: ID!) {
            getTimelineDate(id: $id) {
                _id
                title
                date
                description
                img
            }
        }
    `;
    const variables = { id };
    const payload = {
        query,
        variables
    };
    const headers = { "Content-Type": 'application/json' };
    const response = await axios.post('https://compassion-project.herokuapp.com/graphql', payload, { headers });
    return response.data.data.getTimelineDate;
}