import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTimelineDates } from '../../actions/timeline';

const initialState = {
    dates: [],
    status: 'idle',
    error: null
};

export const fetchDates = createAsyncThunk('dates/fetchDates', async () => {
    const response = await getTimelineDates();
    return response;
});

export const timelineSlice = createSlice({
    name: 'dates',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDates.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchDates.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add fetched dates to the array
                state.dates = state.dates.concat(action.payload)
            })
            .addCase(fetchDates.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export default timelineSlice.reducer;

export const selectAllDates = state => state.dates.dates;

export const selectDateById = (state, dateId) => state.dates.dates.find(date => date._id === dateId)