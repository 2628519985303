/**
 * Author: Natalie Kappele-Miller
 * App: BLM Project
 * Date: June 30, 2021
 */

import React from 'react';
import './BusinessesPage.css';

import usePagination from '../../hooks/usePagination';

import BusinessSpotlight from './BusinessSpotlight';
import BusinessCard from './BusinessCard';

import NextArrow from '../../assets/next-arrow.svg';

const BusinessesPage = ({ history }) => {
    // Categories
    const categories = [
        'Food & Drink',
        'Health',
        'Household',
        'Home',
        'Fashion',
        'Beauty',
        'Art'
    ];
    // Businesses per page
    const itemsPerPage = 6;
    // Set default to first category
    // const defaultCategory = categories[0];

    // Page data from usePagination hook
    const pageData = usePagination(itemsPerPage, categories);
    const { 
        filterBusinesses,
        filteredBusinesses,
        category,
        range,
        page,
        totalPages,
        hasNext,
        hasPrevious,
        handleNext,
        handlePrevious
    } = pageData;

    //console.log('pageData:',pageData)

    /**
     * @function renderBusinesses
     * Returns empty state or BusinessCard components for each result from api call
     * @returns 
     */
    const renderBusinesses = () => {
        if (!range) {
            return (
                <div>Loading...</div>
            )
        }
        if (range && range.length === 0) {
            return (
                <div className="Businesses__EmptyState">No businesses for this category</div>
            )
        } else {
            return (
                <div className="Businesses__CardsRow">
                    {range.map((business) => <BusinessCard key={business._id} business={business} history={history} /> )}
                </div>
            )
        }
    }

    return (
        <div className="Businesses__Bg">
            <div className="Businesses__Wrapper">
                <div className="header">Businesses We Love</div>
                <BusinessSpotlight />
                    <div className="BusinessCategories__Wrapper">
                        <h3 className="BusinessCategories__Header">Categories</h3>
                        <div className="BusinessCategories">
                            {categories.map((categoryItem) => {
                                return (
                                    <div key={categoryItem} onClick={() => filterBusinesses(categoryItem)} className={categoryItem === category ? "BusinessCategories__Cat BusinessCategories__Cat--active": "BusinessCategories__Cat"}>{categoryItem}</div>
                                )
                            })}
                        </div>
                    </div>
                <div className="Businesses__SectionWrapper">
                    {renderBusinesses()}
                </div>
                {filteredBusinesses && filteredBusinesses.length > itemsPerPage && (
                    <div className="BusinessPage__ButtonGroup">
                        <p className="BusinessPage__PagesInfo">Showing {page} of {totalPages} pages</p>
                        <button className="BusinessPage__Previous" disabled={!hasPrevious} onClick={() => handlePrevious()}>
                            <img src={NextArrow} alt="Next" className="BusinessPage__PrevArrow" />
                        </button>
                        <button className="BusinessPage__Next" disabled={!hasNext} onClick={() => handleNext()}>
                            <img src={NextArrow} alt="Previous" className="BusinessPage__NextArrow" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BusinessesPage;