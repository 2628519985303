import React, { useState, useEffect } from 'react';
import './BusinessDetail.css';
import { getBusiness } from '../../actions/business';

import ownerIcon from '../../assets/owner-icon.svg';

const BusinessDetail = ({ match }) => {
    const [business, setBusiness] = useState({});
    useEffect(() => {
        getBusiness(match.params.id)
            .then((response) => {
                setBusiness(response);
            })
            .catch()
    }, [match.params.id]);

    const renderBusinessDetail = () => {
        if (!business) {
            return (
                <div>Loading...</div>
            )
        } else {
            return (
                <div className="BusinessDetail__Wrapper">
                    <div className="BusinessDetail__Row">
                        <div className="BusinessDetail__Placeholder"></div>
                        <div className="BusinessDetail__Content">
                            <div className="BusinessDetail__Header">{business && business.name}</div>
                            <div className="BusinessDetail__Info">
                                <p className="label">Category</p>
                                <p className="value">{business.category}</p>
                            </div>
                            <div className="BusinessDetail__Info">
                                <p className="label">Owners</p>
                                {business.owners && Array.isArray(business.owners) && business.owners.map((owner, index) => {
                                    return (
                                        <div className="owner" key={index}>
                                            <img src={ownerIcon} alt="owner" />
                                            <p className="value">{owner.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="BusinessDetail__Info">
                                <p className="label">Description</p>
                                <p className="value">{business.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="BusinessDetail__Bg">
           {renderBusinessDetail()}
        </div>
    )
};

export default BusinessDetail;